import React from 'react';
import { GatsbyImageProps } from 'gatsby-image';
import { Link, useIntl } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

import '../styles/tiles-list.scss';

import { useBreakpoint, Breakpoint } from '../hooks/use-breakpoint';

export interface TilesListProps {
  items: Array<{
    descriptionLocalizationKey?: string;
    title?: string;
    description?: string | React.ReactNode;
    icon?: React.ReactNode;
    id: string;
    imageFluid?: GatsbyImageProps['fluid'];
    imageSrc?: string;
    link?: string;
    titleLocalizationKey?: string;
    customNode?: React.ReactNode;
  }>;
  itemsPerRowOnDesktop?: number;
  alignment?: 'left' | 'right' | 'center';
}

const TilesList: React.FC<TilesListProps> = ({
  items,
  itemsPerRowOnDesktop,
  alignment = 'center'
}) => {
  const intl = useIntl();
  const isViewportSmall = useBreakpoint(Breakpoint.Small);

  return (
    <div
      className="tiles-list"
      data-aos="fade-up"
      data-aos-duration="100"
      style={
        itemsPerRowOnDesktop
          ? {
              display: 'grid',
              gridTemplateColumns: `repeat(${
                isViewportSmall ? '1' : itemsPerRowOnDesktop
              }, 1fr)`
            }
          : {}
      }
    >
      {items.map((item, index) => {
        const fadeUpDelay = `${index * 100 + 200}ms`;

        return (
          <div
            className={`tiles-list-item ${alignment}`}
            key={item.id}
            style={{
              transition: `box-shadow 300ms ease, background 300ms ease,
            opacity 200ms ease ${fadeUpDelay}, transform 200ms ease ${fadeUpDelay}`
            }}
          >
            {item.icon && <div className="icon">{item.icon}</div>}

            {item.imageFluid && (
              <div className="image">
                <Img fluid={item.imageFluid} />
              </div>
            )}

            {item.imageSrc && (
              <div className="image">
                <img
                  src={item.imageSrc}
                  alt={item.title ?? item.titleLocalizationKey}
                />
              </div>
            )}

            {item.titleLocalizationKey && (
              <div className="title">
                {intl.formatMessage({ id: item.titleLocalizationKey })}
              </div>
            )}

            {item.title && <div className="title">{item.title}</div>}

            {item.descriptionLocalizationKey && (
              <div className="description">
                {intl.formatMessage({ id: item.descriptionLocalizationKey })}
              </div>
            )}

            {item.description && (
              <div className="description">{item.description}</div>
            )}

            {item.link && <Link className="link" to={item.link} />}

            {item.customNode}
          </div>
        );
      })}
    </div>
  );
};

export default TilesList;
