import { storage } from '../fragments/firebase-storage';
import { notifier } from '../fragments/notifier';

export const previewPDF = async (filePath: string) => {
  try {
    const windowReference = window.open();

    const url = await storage()
      .ref(filePath)
      .getDownloadURL();

    if (windowReference) {
      windowReference.location = url;
    }
  } catch (error) {
    notifier('error', { message: error.message });
  }
};
