import Button from './button';
import React, { useState } from 'react';

import { previewPDF } from '../utils/preview-pdf';

interface SectionPreviewPdfProps {
  title?: string;
  heading?: string;
  paragraph?: string;
  buttonLabel: string;
  filePathInStorage: string;
}

const SectionPreviewPdf: React.FC<SectionPreviewPdfProps> = ({
  title,
  heading,
  paragraph,
  buttonLabel,
  filePathInStorage
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handlePreviewPdf = async () => {
    try {
      setLoading(true);
      await previewPDF(filePathInStorage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <section className="cta-section section-container section-light">
      <div className="container centered">
        <div className="section-headings-container" data-aos="fade-up">
          {title && <p className="text-uppercase text-small">{title}</p>}
          {heading && <h3>{heading}</h3>}
          {paragraph && <p>{paragraph}</p>}

          <Button
            type="primary"
            loading={loading}
            onClick={handlePreviewPdf}
            label={buttonLabel}
          />
        </div>
      </div>
    </section>
  );
};

export default SectionPreviewPdf;
