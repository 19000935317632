import React from 'react';
import Button from './button';

import '../styles/cta-section.scss';
import { useIntl, IntlShape } from 'gatsby-plugin-intl';
import { AVAILABLE_CTAS_CONTENTS_COUNT } from '../config/ctas';

interface Props {
  heading?: string;
  paragraph?: string;
  extraContent?: React.ReactNode;
  button?: {
    text?: string;
    to?: string;
  };
}

const getContent = (intl: IntlShape): Props => {
  const index = Math.floor(Math.random() * AVAILABLE_CTAS_CONTENTS_COUNT);

  return {
    heading: intl.formatMessage({ id: `cta.HEADING_${index}` }),
    paragraph: intl.formatMessage({ id: `cta.PARAGRAPH_${index}` }),
    button: {
      text: intl.formatMessage({ id: `cta.GET_FREE_ESTIMATION` }),
      to: '/contact'
    }
  };
};

export const CtaSection: React.FC<Props> = ({ extraContent, ...props }) => {
  const intl = useIntl();

  const randomContent = getContent(intl);
  const content: Props = {
    ...randomContent,
    ...props,
    button: {
      ...props.button,
      ...randomContent.button
    }
  };

  return (
    <section className="cta-section section-container section-light">
      <div className="container centered">
        <div className="section-headings-container" data-aos="fade-up">
          <h3>{content.heading}</h3>
          <p>{content.paragraph}</p>
          {extraContent}
          <Button
            label={content.button?.text ?? ''}
            modeClassName="primary"
            href={content.button?.to}
          />
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
