import { notification } from 'antd';

interface NotifierConfig {
  description?: string;
  icon?: React.ReactNode;
  message: string;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}

export const notifier = (
  type: 'success' | 'info' | 'warning' | 'error',
  { message, description, icon, placement }: NotifierConfig
) =>
  notification[type]({
    description,
    icon,
    message,
    placement
  });
