import * as firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAshCrCqmadeS3CkfU0dGK50Ppu2kzGHAc',
  appId: 'shop-mikino',
  authDomain: 'shop-mikino.appspot.com',
  projectId: 'shop-mikino',
  storageBucket: 'shop-mikino.appspot.com'
};

if (firebase?.initializeApp) {
  firebase?.initializeApp(firebaseConfig);
}

export default firebase;
