import { useMediaQuery } from './use-media-query';

export enum Breakpoint {
  Small = 'small',
  Medium = 'medium'
}

const makeMediaQuery = (breakpoint: Breakpoint) => {
  switch (breakpoint) {
    case Breakpoint.Small:
      return '(max-width: 768px)';
    case Breakpoint.Medium:
      return '(max-width: 992px)';
    default:
      throw new Error(`Unrecognized breakpoint ${breakpoint}.`);
  }
};

export const useBreakpoint = (breakpoint: Breakpoint): boolean =>
  useMediaQuery(makeMediaQuery(breakpoint));
