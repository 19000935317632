import { useEffect, useState } from 'react';
import win from '../imports/window';

export const useMediaQuery = (mediaQuery: string): boolean | undefined => {
  const mql = win?.matchMedia(mediaQuery);

  const [matches, setMatches] = useState<boolean | undefined>(mql?.matches);

  useEffect(() => {
    const eventHandler = (event: MediaQueryListEvent) =>
      setMatches(event.matches);

    mql?.addListener(eventHandler);

    return () => mql?.removeListener(eventHandler);
  }, []);

  return matches;
};
